import { Component, Injector, OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import Debug from 'debug';
import { ActivatedRoute, Router } from '@angular/router';
import { DgoodsSessionStoreProvider } from '@modeso/twint-lib-session-fe';
import { IAppStateProtocolModel } from '../../shared/models/appStateProtocol.model';
import { Store } from '@ngrx/store';
import * as fromApp from './../../state/app.state.reducer';
import * as fromActions from './../../state/app.state.actions';
import { map } from 'rxjs/operators';
import { ProductStoreProvider } from '@modeso/ihub-lib-products-fe';
import { UserProvider } from '@modeso/ihub-lib-user-fe';
import { take, filter } from 'rxjs/operators';

const debug = Debug('dgoods:shop:OneTimeUrlStartPage');

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-page-fakeStart',
  templateUrl: './onetimeurlStart.page.html',
  styleUrls: ['./onetimeurlStart.page.scss']
})
export class OneTimeUrlStartPage extends BasePageComponent implements OnInit {

  personId: any;

  constructor(injector: Injector,
              private sessionProvider: DgoodsSessionStoreProvider,
              private route: ActivatedRoute,
              private router: Router,
              private store: Store<fromApp.AppState>,
              private productProvider: ProductStoreProvider,
              private userProvider: UserProvider
  ) {
    super(injector);
    debug('Test Start');
    debug('OneTimeUrlStartPage');
    let iosProtocol = this.route.snapshot.queryParamMap.get('returnAppScheme');
    const androidProtocol = this.route.snapshot.queryParamMap.get('returnAppPackage');
    debug(iosProtocol);
    debug(androidProtocol);
    if (iosProtocol === null || iosProtocol === undefined) {
      iosProtocol = 'iosProtocol';
    }
    const protocol = androidProtocol ? androidProtocol : iosProtocol;
    const device = androidProtocol ? 'android' : 'ios';
    const appStateModel: IAppStateProtocolModel = {
      protocol,
      device,
    };
    localStorage.removeItem('protocol');
    localStorage.removeItem('device');
    this.store.dispatch(fromActions.updateStoreProtocol({ payload: appStateModel }));
    localStorage.setItem('protocol', protocol);
    localStorage.setItem('device', device);

    debug(this.route.snapshot.url[1].path);
    const urltoken = this.route.snapshot.url[1].path;
    this.sessionProvider.start({ checkInUrl: urltoken, issuer: protocol }).pipe(
      map(urlState => {
        if (urlState.isValidUrl) {
          debug('OneTimeUrlStartPage say\'s the url is valid');
          this.router.navigate(['../../de-ch/landing-buy']);
        } else {
          debug('OneTimeUrlStartPage navigates to mocked checkin');
          this.router.navigateByUrl('/de-ch/login');
          return false;
        }
      })
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscriptions.push(this.sessionProvider.getSessionState$().subscribe(state => {
      debug(state);
      if (state.isValidUrl) {
        debug('OneTimeUrlStartPage ngOnInit say\'s the url is valid');
        localStorage.removeItem('email');
        localStorage.removeItem('twintTC');
        localStorage.removeItem('checkInDate');
        localStorage.removeItem('delayPayment');
        localStorage.removeItem('productLineId');
        localStorage.setItem('checkInDate', state.checkInDate.toString());
        sessionStorage.setItem('terminalId', state.terminalId);
        sessionStorage.setItem('isP2P', state.isP2P.toString());
        // Initialize Person if not set
        this.personId = localStorage.getItem('personId') ? localStorage.getItem('personId') : undefined;
        if (!this.personId) {
            this.getPerson();
        }
        if (state.delayPayment) {
          localStorage.setItem('delayPayment', state.delayPayment.toString());
        }
        if (state.user) {
          if (state.user.email) {
            localStorage.setItem('email', state.user.email);
          }
          if (state.user.twintTC) {
            localStorage.setItem('twintTC', state.user.twintTC.toString());
          }
        }
        if (state.productLineId) {
          localStorage.setItem('productLineId', state.productLineId);

          debug('needs to navigate to product');
          try {
            const storedProducts = localStorage.getItem('products');
            if (storedProducts) {
              const savedProducts = JSON.parse(storedProducts);
              const searchedProduct = savedProducts.find(x => x.product === state.productLineId);
              debug('searchedProduct', searchedProduct);
              if (searchedProduct === undefined) {
                throw new Error('needs to fetch products');
              } else {
                this.router.navigate([`../../de-ch/insurance-detail/${state.productLineId}`]);
              }
            } else {
              throw new Error('needs to fetch products');
            }
          } catch (error) {
            debug('product not found', error);


            this.productProvider.getAllProducts$().pipe(
              filter(products => products !== null),
              filter(products => products.length >= 1),
              take(1)).subscribe((products) => {
              debug('fetch all products on init of one time url', products);
              if (products) {
                localStorage.setItem('products', JSON.stringify(products));
                const searchedProduct = products.find(x => x.product === state.productLineId);
                debug('searchedProduct', searchedProduct);
                if (searchedProduct === undefined) {
                  this.router.navigate(['../../de-ch/landing-buy']);
                } else {
                  this.router.navigate([`../../de-ch/insurance-detail/${state.productLineId}`]);
                }
              } else {
                this.router.navigate(['../../de-ch/landing-buy']);
              }
            });
          }

        } else {
          this.router.navigate(['../../de-ch/landing-buy']);
        }

      }
    }));
  }

  getPerson() {
    let index = 0;
    this.userProvider.getPersonOfUser$().pipe(take(2))
      .subscribe((person) => {
        index++;
        this.personId = undefined;
        if (index === 2) {
          if (person) {
            this.personId = person._id;
            localStorage.setItem('personId', this.personId);
          }
        }
    });
  }

}
